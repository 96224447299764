<template>
  <div class="filter" style="display: flex;">
    <div class="filter__item filter__item_select" :style="`width:${isMobile?'50%':'380px'};`">
      <v-select
        solo
        flat
        outlined
        placeholder="Объект"
        :items="objects"
        item-value="id"
        item-text="name"
        dense
        v-model="object"
        @change="changeObject"
      >
        <template v-slot:selection="{ item }">
                          <div v-if="$store.state.isMobile">{{ item.name.slice(0,5) }}...</div>
                <div v-if="!$store.state.isMobile">{{ item.name }}</div>
          <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
          <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
          <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
        </template>
        <template v-slot:item="{ item }">
                          <div v-if="$store.state.isMobile">{{ item.name.slice(0,5) }}...</div>
                <div v-if="!$store.state.isMobile">{{ item.name }}</div>
          <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
          <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
          <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
        </template>
      </v-select
      >
    </div>
    <div style="display: flex;" :style="`width:${isMobile?'50%':'380px'};`" v-show="period">
      <div class="filter__item filter__item_date">
        <DatePicker
          ref="datepicker"
          :shortcuts="shortcuts"
          v-model="dates"
          @change="changeDates"
          type="date"
          range
          placeholder="Select datetime range"
        >
          <template v-slot:input>
            <v-text-field
              solo
              flat
              outlined
              :placeholder="$t('statistics[\'Период\']')"
              readonly
              hide-details
              class="mobile_input"
              dense
              v-model="date_model"
            ></v-text-field>
          </template>
        </DatePicker>
      </div>
      <div>
        <v-btn icon small color="primary" @click="reloadData"
          ><v-icon>mdi-refresh</v-icon></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "ObjectFilter",
  components: { DatePicker },
  props: {
    objects: Array,
    object_id: Number,
    period: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isMobile:false,
      mountedF: false,
      shortcuts: [
        {
          text: "Today",
          onClick() {
            const start = new Date();
            const end = new Date();
            return [start, end];
          },
        },
        {
          text: "Yesterday",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 24 * 3600 * 1000);
            end.setTime(end.getTime() - 24 * 3600 * 1000);
            return [start, end];
          },
        },
        {
          text: "Last 7 day",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 7 * 24 * 3600 * 1000);
            return [start, end];
          },
        },
        {
          text: "Last 30 day",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 30 * 24 * 3600 * 1000);
            return [start, end];
          },
        },
        {
          text: "This month",
          onClick() {
            const start = new Date();
            const end = new Date();
            const firstDayCurrentMonth = new Date(
              start.getFullYear(),
              start.getMonth(),
              1
            );
            const lastDayCurrentMonth = new Date(
              end.getFullYear(),
              end.getMonth() + 1,
              0
            );
            return [firstDayCurrentMonth, lastDayCurrentMonth];
          },
        },
        {
          text: "Last month",
          onClick() {
            const dateLast = new Date();
            const firstDayCurrentMonth = new Date(
              dateLast.getFullYear(),
              dateLast.getMonth() - 1,
              1
            );
            const lastDayCurrentMonth = new Date(
              dateLast.getFullYear(),
              dateLast.getMonth(),
              0
            );
            return [firstDayCurrentMonth, lastDayCurrentMonth];
          },
        },
      ],
      dates: [],
      menu_dates: false,
      date_end: null,
      date_start: null,
      menu_date_end: false,
      menu_date_start: false,
      object: this.objects[0],
      date_model: null,
    };
  },
  computed: {},
  async mounted() {
    this.dates[0] = this.$moment().subtract(1, "month").toDate();
    this.dates[1] = new Date();
    this.dateRangeText();
    if (this.$store.state.statistics.object_id) {
      this.object = this.objects.find(
        (object) => +object.id === +this.$store.state.statistics.object_id
      );
      await this.changeObject(this.$store.state.statistics.object_id);
    } else {
      this.$store.state.statistics.object_id = this.objects[0].id;
    }
    this.$refs.datepicker.currentValue = this.dates;
    this.$refs.datepicker.handleSelectDate(this.dates);
  },
  methods: {
    dateRangeText() {
      let date_start = null;
      let date_end = null;
      if (this.dates[0] && this.dates[1]) {
        date_start = this.$moment(this.dates[0]).format("DD.MM.YYYY");
        date_end = this.$moment(this.dates[1]).format("DD.MM.YYYY");
      } else if (this.dates[0]) {
        date_start = this.$moment(this.dates[0]).format("DD.MM.YYYY");
      } else if (this.dates[1]) {
        date_end = this.$moment(this.dates[1]).format("DD.MM.YYYY");
      } else {
        this.date_model = "";
      }
      this.date_model = [date_start, date_end].join(" - ");
    },
    async changeObject(id) {
      if(this.mountedF){
        await this.$emit("changeObject", id);
      }
    },
    async changeDates() {
      this.menu_dates = false;
      if (this.dates[0] && this.dates[1]) {
        if (this.dates[0] > this.dates[1]) {
          let start = this.dates[0];
          let end = this.dates[1];
          this.dates[0] = end;
          this.dates[1] = start;
        }
          await this.$emit("changeDates", {
            date_start: this.$moment(this.dates[0]).unix(),
            date_end: this.$moment(this.dates[1]).unix(),
          })
      } else {
          await this.$emit("changeDates", {
            date_start: null,
            date_end: null,
          });
      }
      this.dateRangeText();
      this.mountedF = true;
    },
    async reloadData() {
      await this.$emit("reloadData");
    },
    clearDate() {
      this.dates[0] = null;
      this.dates[1] = null;
      // this.changeDates();
    },
  },
  created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }
};
</script>

<style lang="scss">
.v-date-picker-title__year {
  margin-bottom: 0 !important;
  font-size: 24px !important;
}
.v-date-picker-title__date {
  font-size: 0 !important;
}
.v-date-picker-header__value {
  button {
    padding: 0.5rem 0 !important;
  }
}
.mx-datepicker-range {
  width: 100%;
}
.tariff_obj {
  margin-left:5px;
  display: inline-block;
  padding: 4px 6px;
  text-transform: uppercase;
  border-radius: 0 5px 0 5px;
  background: #007db5;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: #fff;
}
  .mobile_input input{
    max-width:90%;
  }
</style>
